import React from "react"
import 'twin.macro'
import { Link } from "gatsby"

function TagLink({tag}) {
  const tagPath = encodeURIComponent(tag)
  return (
    <Link
      tw="inline-block bg-blue-100 text-blue-600 text-sm px-2 py-1 rounded my-0.5 mr-1 no-underline dark:(bg-blue-900 text-blue-100)"
      to={`/places?c=${tagPath}`}>
      {tag}
    </Link>
  )
}

export default TagLink
