import React from "react"
import 'twin.macro'

const Accent = ({ children, ...props }) => (
  <div tw="text-xs font-semibold text-thirdgray-400 leading-normal uppercase tracking-widest md:(text-sm) dark:(text-thirdgray-dark-400)">
    {children}
  </div>
)

const H2 = ({ children, ...props }) => (
  <h2 tw="font-serif text-2xl lg:(text-2xl) xl:(text-3xl) text-thirdgray-900 dark:(text-thirdgray-dark-900)" {...props}>
    {children}
  </h2>
)

const H3 = ({ children, ...props }) => (
  <h3 tw="font-serif text-xl lg:(text-xl) xl:(text-xl) text-thirdgray-900 dark:(text-thirdgray-dark-900)" {...props}>
    {children}
  </h3>
)

const P = ({ children, ...props }) => (
  <p tw="font-serif text-lg lg:(text-xl) text-thirdgray-900 dark:(text-thirdgray-dark-900)" {...props}>
    {children}
  </p>
)

export { Accent, H2, H3, P }
